import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Timestamp } from 'rxjs/internal/operators/timestamp';


@Injectable()
export class UploadNiFileService {

  linkAPI: string = "";
  URL: string = "assets/config/web-config.json";
  constructor(private httpClient: HttpClient) {

    // this.getImportData(_dataCsv);


  }

  getLinkAPI(): any {

    return this.httpClient.get(this.URL);
  }

  UploadNiFile(selectedImage: File, fileName: string): any {
    const headers = new HttpHeaders();
    headers.append("enctype", "multipart/form-data");
    headers.append("Access-Control-Allow-Origin", "*");

    const fd = new FormData();
    fd.append('imageProfile', selectedImage, fileName);
    return this.httpClient.post(this.linkAPI + 'uploadImage.php', fd, { headers });

  }

  UploadFileToFirebase(image: File): any {
    const storageRef = firebase.storage().ref().child('image_prakard/' + image.name);
    return storageRef.put(image);

  }

  DeleteFileInFirebase(item: any): any {

    return item.delete();

  }

  getTimeStamp(): any {
    const timeStamp = firebase.firestore.FieldValue.serverTimestamp();
    return timeStamp;
  }
}
